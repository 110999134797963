<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="overview"></submenu-account>
       
    <div class="submenu-content">
      
        <h2>Account Overview</h2>
        
        <div v-if="error" class="alert alert-danger">{{error}}</div>

        <div v-if="is_customer">

          <div class="mt-4 mb-4">
            <div>Login Email: {{ data.email }}</div>
            <div>Customer ID: {{ data.custno }}</div>
            <div>Business Name: {{ data.company }}</div>
            <div>
              Email Newsletter: 
              <span v-if="data.eblast">Subscribed</span>
              <span v-else>Not Subscribed Yet – click <router-link :to="{ name: 'Newsletter' }">here</router-link> to join the email list</span>
            </div>
            
            <br><br><br>
            
            <div>
              <h4>Ma Labs Account Manager</h4>
              <div class="shadow p-3 rounded">
              <p> To get help from our friendly sales team, please contact us via phone or email below. </p>
              <div v-if="data.sales">Name: {{data.sales}}</div>
              <div v-if="data.sales_email">Email: {{data.sales_email}}</div>
              <div v-if="data.sales_phone">Phone: {{data.sales_phone}}</div>
              </div>
            </div>
            <br><br>

            <div>
              <h4>Address</h4>
              <div class="shadow p-3 rounded">
              <div>Default Billing Address: </div>        
                <div class="row justify-content-md-left">
                  <div class="col">
                    {{data.addr1}}
                    <span v-if="data.addr2">{{data.addr2}} <br/></span>
                  </div>
                  <div class="col">
                    {{data.city}}, {{data.state}} {{data.zip}}
                  </div>
                  <div class="col">
                    {{data.country}}
                  </div>
                </div>
             <br>
              <div>Default Ship-to Address: </div> 
                <div class="row justify-content-md-left">
                    <div class="col">
                      {{data.saddr1}} <br/>
                      <span v-if="data.saddr2">{{data.saddr2}} <br/></span>
                    </div>
                    <div class="col">
                      {{data.scity}}, {{data.sstate}} {{data.szip}}
                    </div>
                    <div class="col">
                      {{data.country}}
                    </div>
                </div>
                <br>  
                <p><h6>*Please contact your Ma Labs Account Manager if you need to modify address(es). </h6></p>  
              </div>         
            </div>        
            
          </div>
        </div>

    </div> 


  </div>
</template>

<script>
import submenuAccount from '../../components/nav/submenuAccount.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { useStore } from 'vuex'
import { ref } from 'vue'
import { getAuthAPI } from '../../utils/axios-api'

export default {
  name: 'AccountOverview',
  components: { submenuAccount, BreadcrumbPage },
  setup() {
    
    const store = useStore()
    const data = ref(false)
    const is_customer = ref(store.getters.isCustomer)
    const error = ref(null)
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Account Overview", link: "AccountOverview"})

    if (is_customer.value == true) {
      getAuthAPI.get('/account/my-account/')
      .then(response => {
        data.value = response.data.results
      })
      .catch(err => {
          error.value = err.message
      })
    }

    return {error,
            data,
            is_customer,
            breadcrumb_links
            }
  }
}
</script>

<style scoped>
.shadow{
  width:50%;
  background-color: #E8E8E8;
}
</style>